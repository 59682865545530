<template>
  <div class="count-down" :class="{ ending: ending }">
    <div>
      <div>{{ $t('timeFinishPurchase') }}</div>
      <div class="d-flex align-end justify-center">
        <v-icon size="" color="white">mdi-timer</v-icon>
        <div class="count-down__time">{{ value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    value: String,
    ending: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.count-down {
  max-width: 200px;
  padding: 0.5em;
  border-radius: 6px;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-super-small);
  line-height: 1;
  box-shadow: var(--shadow1-over);
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  &.ending {
    animation: bounce-out-top 1s both;
  }

  > div {
    margin: auto;
  }
  &__time {
    width: 72px;
    margin-top: 4px;
    margin-left: 4px;
    font-size: 2em;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce-out-top {
  0% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  5% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  }
  15% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  25% {
    transform: translateY(-38px);
    animation-timing-function: ease-in;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  52% {
    transform: translateY(-75px);
    animation-timing-function: ease-in;
  }
  70% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    transform: translateY(-800px);
    opacity: 0;
  }
}
</style>
